define("ember-table/components/ember-th/sort-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Aaw0jPVm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isSorted\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"et-sort-indicator \",[28,\"if\",[[24,[\"isSortedAsc\"]],\"is-ascending\",\"is-descending\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[24,[\"columnMeta\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isMultiSorted\"]]],null,{\"statements\":[[0,\"        \"],[1,[22,\"sortIndex\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"isSortable\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"class\",\"et-sort-toggle et-speech-only\"],[8],[0,\"Toggle Sort\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-th/sort-indicator/template.hbs"
    }
  });

  _exports.default = _default;
});