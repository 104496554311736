define("ember-cli-new-version/components/new-version-notifier/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mJkxuAD+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"message\"]]],null,{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1,[[24,[\"version\"]],[24,[\"lastVersion\"]],[28,\"action\",[[23,0,[]],\"reload\"],null],[28,\"action\",[[23,0,[]],\"close\"],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"update-notification\"],[8],[0,\"\\n      \"],[1,[22,\"message\"],false],[0,\" \"],[4,\"if\",[[24,[\"showReload\"]]],null,{\"statements\":[[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"update-notification__btn\"],[3,\"action\",[[23,0,[]],\"reload\"]],[8],[1,[22,\"reloadButtonText\"],false],[9]],\"parameters\":[]},null],[0,\"\\n      \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"update-notification__close\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"×\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-new-version/components/new-version-notifier/template.hbs"
    }
  });

  _exports.default = _default;
});