define("ember-table/components/ember-tfoot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ty3qQf9Z",
    "block": "{\"symbols\":[\"rowValue\",\"api\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"wrappedRowArray\"]]],null,{\"statements\":[[4,\"-ember-table-private/row-wrapper\",null,[[\"rowValue\",\"columns\",\"columnMetaCache\",\"rowMetaCache\",\"canSelect\",\"rowSelectionMode\",\"checkboxSelectionMode\"],[[23,1,[]],[24,[\"columns\"]],[24,[\"columnMetaCache\"]],[24,[\"rowMetaCache\"]],[24,[\"canSelect\"]],[24,[\"rowSelectionMode\"]],[24,[\"checkboxSelectionMode\"]]]],{\"statements\":[[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"      \"],[14,3,[[28,\"hash\",null,[[\"rowValue\",\"rowMeta\",\"cells\",\"rowSelectionMode\",\"row\"],[[23,2,[\"rowValue\"]],[23,2,[\"rowMeta\"]],[23,2,[\"cells\"]],[23,2,[\"rowSelectionMode\"]],[28,\"component\",[\"ember-tr\"],[[\"api\"],[[23,2,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"ember-tr\",null,[[\"api\"],[[23,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-tfoot/template.hbs"
    }
  });

  _exports.default = _default;
});