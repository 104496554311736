define("ember-table/components/ember-tbody/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pNO7bYSl",
    "block": "{\"symbols\":[\"rowValue\",\"api\",\"&inverse\",\"&default\"],\"statements\":[[4,\"vertical-collection\",[[24,[\"wrappedRows\"]]],[[\"containerSelector\",\"estimateHeight\",\"key\",\"staticHeight\",\"bufferSize\",\"renderAll\",\"firstReached\",\"lastReached\",\"firstVisibleChanged\",\"lastVisibleChanged\",\"idForFirstItem\"],[[24,[\"_containerSelector\"]],[24,[\"estimateRowHeight\"]],[24,[\"key\"]],[24,[\"staticHeight\"]],[24,[\"bufferSize\"]],[24,[\"renderAll\"]],[24,[\"firstReached\"]],[24,[\"lastReached\"]],[24,[\"firstVisibleChanged\"]],[24,[\"lastVisibleChanged\"]],[24,[\"idForFirstItem\"]]]],{\"statements\":[[4,\"-ember-table-private/row-wrapper\",null,[[\"rowValue\",\"columns\",\"columnMetaCache\",\"rowMetaCache\",\"canSelect\",\"rowSelectionMode\",\"checkboxSelectionMode\"],[[23,1,[]],[24,[\"columns\"]],[24,[\"columnMetaCache\"]],[24,[\"rowMetaCache\"]],[24,[\"canSelect\"]],[24,[\"rowSelectionMode\"]],[24,[\"checkboxSelectionMode\"]]]],{\"statements\":[[4,\"if\",[[25,4]],null,{\"statements\":[[0,\"      \"],[14,4,[[28,\"hash\",null,[[\"rowValue\",\"rowMeta\",\"cells\",\"rowSelectionMode\",\"row\"],[[23,2,[\"rowValue\"]],[23,2,[\"rowMeta\"]],[23,2,[\"cells\"]],[23,2,[\"rowSelectionMode\"]],[28,\"component\",[\"ember-tr\"],[[\"api\"],[[23,2,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"ember-tr\",null,[[\"api\"],[[23,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[4,\"if\",[[24,[\"shouldYieldToInverse\"]]],null,{\"statements\":[[0,\"  \"],[14,3],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-tbody/template.hbs"
    }
  });

  _exports.default = _default;
});